import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import ReactGA from 'react-ga'
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Layout from "../components/Layout"
import PrimaryProduct from "../components/PrimaryProduct"
import SEO from "../components/SEO"
import {
  color, desktop, Flex, mobile, radius, SVG, Text, View
} from "../components/Styles"
import catPNG from "../images/cat.png"
import partnerABCWallet from "../images/partnerABCWallet.svg"
import partnerDappReview from "../images/partnerDappReview.svg"
import partnerIOST from "../images/partnerIOST.svg"
import partnerIOSTABC from "../images/partnerIOSTABC.svg"
import partnerTP from "../images/partnerTP.svg"




ReactGA.initialize("UA-143690493-3");
if (typeof window !== 'undefined') {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export default () => {
  const { t, i18n } = useTranslation()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            mission
            description
            author
          }
        }
      }
    `
  )
  return (
    <Layout>
      <SEO title="" />

      <View
        display={["none", null]}
        z={-4}
        position={"absolute"}
        top="0"
        right="0"
        overflow="hidden"
      >
        <View transform={{ x: "45%", y: "-40%" }}>
          <Rotate duration={5}>
            <BrandCircles2 />
          </Rotate>
        </View>

        <View
          position="absolute"
          top="0"
          right="0"
          transform={{ x: "45%", y: "-40%" }}
        >
          <Rotate duration={4}>
            <BrandCircles3 />
          </Rotate>
        </View>
      </View>

      <Row pt={[4, 3]} pb={[4, 6]} align={["center", null]}>
        <Flex aic gap={0.75} responsive>
          <View
            scale={-1}
            r={radius.pill}
            px={1}
            py={0.5}
            color={color.white}
            bg={color.liebi}
            weight={"bold"}
          >
            {t("新")}
          </View>
          <View as="span">
            <a
              href="https://omni.ls"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Omnichain流动性质押去中心化应用omnils已启动")}
            </a>
          </View>
        </Flex>

        <View scale={i18n.language === 'en' ? 3 : 4} pt={0.75} pb={[0.5, 0.35]} weight={"bold"}>
          {t(site.siteMetadata.mission)}
        </View>

        <View scale={i18n.language === 'en' ? 0.2 : 1} px={[1.5, null]} color={color.gray} paragraph>
          {t(site.siteMetadata.description)}
        </View>

        <Flex aic display={"inline-flex"} pt={[3, 5]}>
          <View scale={1} mr={0.75} color={color.gray2}>
            {t("我们在做什么")}
          </View>
          <SVG svg={Arrow} scale={3} />
        </Flex>
      </Row>

      <ProductRow>
        <Flex responsive>
          <PrimaryProduct />
        </Flex>
      </ProductRow>

      <Row>
        <Quote scale={i18n.language === 'en' ? 3 : 4}>
          {t("期待与你")}
          <br />
          {t("构建良性加密世界")}
        </Quote>

        <Flex childFlex={1} gap={[3, 5]} responsive>
          <Feature
            title={t("开源")}
            icon={OpenSource}
            themeColor={color.liebi}
            description={t("源代码在 GitHub 上提供，并附有开源许可证。您可以选择托管在自己的服务器上。")}
          />
          <Feature
            icon={Transparent}
            themeColor={color.pool}
            title={t("透明")}
            description={t("猎币定期发布透明度报告，会尽可能详细的说明，猎币多签合约排除单点作恶风险，保障用户资产安全。")}
          />
        </Flex>
      </Row>

      <Row>
        <Flex jcsb responsive>
          <View>
            <Quote scale={i18n.language === 'en' ? 3 : 4}>
              {t("有问题吗？")}
              <br />
              {t("来这里找找答案")}
            </Quote>

            <Link to="/contact">
              <View
                display={"inline-block"}
                scale={1}
                weight={"bold"}
                px={1.25}
                py={1.1}
                color={color.white}
                bg={color.black}
                r={radius.pill}
              >
                {t("联系我们")}
              </View>
            </Link>
          </View>

          <Cat>
            <img src={catPNG} alt={t("猎币矿池")} />
          </Cat>
        </Flex>
      </Row>
      <div style={{ paddingBottom: '250px' }} />
      <Row style={{ display: 'none' }}>
        <View scale={2} mt={[2.5, 5]} mb={[2, 4]}>
          <View scale={1} color={color.gray}>
            {t("合作伙伴")}
          </View>

          <Partner>
            <Flex mt={1.5} gap={1} flexWrap jcc>
              {/*<img src={partnerPolkadot} alt="" />*/}
              {/*<img src={partnerWeb3} alt="" />*/}
              <img src={partnerIOST} alt="" />
              <img src={partnerTP} alt="" />
              <img src={partnerABCWallet} alt="" />
              <img src={partnerIOSTABC} alt="" />
              <img src={partnerDappReview} alt="" />
            </Flex>
          </Partner>

          {/*<PartnerList*/}
          {/*list={[*/}
          {/*partnerPolkadot,*/}
          {/*partnerWeb3,*/}
          {/*partnerIOST,*/}
          {/*partnerCocosBCX,*/}
          {/*partnerTP,*/}
          {/*partnerABCWallet,*/}
          {/*partnerIOSTABC,*/}
          {/*partnerDappReview,*/}
          {/*]}*/}
          {/*/>*/}
        </View>
      </Row>
    </Layout>
  )
}

const PartnerList = ({ list }) => (
  <Flex mt={1.5} gap={1} flexWrap jcc>
    {list.map((Logo, i) => (
      <StyledPartner key={i} scale={7} p={1}>
        <Logo />
      </StyledPartner>
    ))}
  </Flex>
);

const StyledPartner = styled(Text)`
  svg {
    height: ${p => (p.height ? p.height : 1) + "em"};
    width: auto;
    fill: ${color.lightGray};
    @media (hover: hover) {
      :hover {
        fill: ${color.gray};
      }
    }
  }
`;

export const Row = styled(View)`
  ${mobile`
    padding-left:1.5em;
    padding-right:1.5em;
  `}
  ${desktop`
    max-width:1080px;
  `}
  margin:0 auto;
`

const ProductRow = styled(View)`
  ${mobile`
    padding-left:1em;
    padding-right:1em;
  `}
  ${desktop`
    max-width:1260px;
  `}
  margin:0 auto;
`

const Rotate = styled.div`
  animation-name: spin;
  animation-duration: ${p => p.duration && p.duration + "s"};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: ${p => p.reverse && "reverse"};
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

const Quote = styled(View).attrs({
  mt: 3,
  mb: [1.25, 1],
  paragraph: true,
})``

const Feature = ({ icon, title, description, themeColor }) => (
  <View>
    <Flex aic>
      <SVG svg={icon} scale={3} fill={themeColor} />
      <View scale={2} ml={[0.5, 0.75]} color={themeColor} weight={"bold"}>
        {title}
      </View>
    </Flex>

    <View color={color.gray} pl={[null, 5.2]} pt={[1.5, 1]} paragraph>
      {description}
    </View>
  </View>
)

const Cat = styled(View)`
  align-self: "flex-end";
  ${mobile`
    text-align:right;
    img {
      width: 75%;
    }
  `};
  ${desktop`
    width: 45%;
    text-align:right;
    img {
      width: 100%;
      transform:translateX(15%)  translateY(80%);
    }
  `};
`

const BrandCircles2 = () => (
  <svg
    width="1200"
    height="1200"
    viewBox="0 0 1200 1200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="600"
      cy="600.135"
      r="445.63"
      stroke="#29B6A2"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.7739 600.136C81.7739 553.433 87.9519 508.173 99.5351 465.129C134.175 336.405 217.153 227.496 327.808 159.064"
      stroke="#554DF0"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const BrandCircles3 = () => (
  <svg
    width="1200"
    height="1200"
    viewBox="0 0 1200 1200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9292 764C83.2463 953.488 229.325 1104.19 416 1164.95"
      stroke="#DADFE4"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Arrow = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1738 12L20.1738 27.5754M20.1738 27.5754L27 20.6087M20.1738 27.5754L13.3475 20.6087"
      stroke="#AFAFAF"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const OpenSource = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.3848 58.125C8.55031 53.8821 0.877441 43.3359 0.877441 30.9976C0.877441 14.9136 13.9161 1.875 30 1.875C46.0839 1.875 59.1226 14.9136 59.1226 30.9976C59.1226 43.3359 51.4496 53.8822 40.6151 58.125L33.6593 40.3492C37.3942 38.8865 40.0392 35.251 40.0392 30.9977C40.0392 25.4531 35.5445 20.9584 29.9999 20.9584C24.4554 20.9584 19.9607 25.4531 19.9607 30.9977C19.9607 35.251 22.6057 38.8865 26.3405 40.3492L19.3848 58.125Z" />
  </svg>
)

const Transparent = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M50.9144 53.2427C50.9144 55.9533 48.7171 58.1506 46.0065 58.1506H13.9934C11.2828 58.1506 9.08545 55.9533 9.08545 53.2427V6.75728C9.08545 4.04671 11.2828 1.84937 13.9934 1.84937H30.2648V15.9551C30.2648 19.5692 33.1946 22.499 36.8087 22.499H50.9144V53.2427Z" />
    <path d="M33.2874 1.84937L50.9144 19.4764H36.5593C34.7523 19.4764 33.2874 18.0115 33.2874 16.2045V1.84937Z" />
  </svg>
)

const Partner = styled(Flex)`
  img {
    height: 1em;
    width: auto;
  }
`
