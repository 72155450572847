import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Flex, SVG, View, color } from "./Styles"

export default ({ screen }) => {
  const { t, i18n } = useTranslation()

  return (
    <>
      {/* <View bg={color.gray6} p={1} color={color.gray}>
        <Flex aic gap={0.75}>
          <SVG svg={flag} fill={color.gray3} />
          <Link to="/node">{t("猎币节点托管，助你进入 Staking 时代")}</Link>
        </Flex>
      </View> */}

      <Flex aic jcsb px={[1.5, 4]} py={[1.5, 2]}>
        <Flex gap={[1, 2]}>
          <Link style={{ display: "flex", alignItems: "center" }} to="/">
            {
              i18n.language === 'zh' ? (
                <SVG svg={logo} scale={2} />
              ) : (
                <SVG svg={logoEn} scale={2} />
              )
            }

          </Link>
          <Button
            ghost
            onClick={() => {
              i18n.changeLanguage(i18n.language === "zh" ? "en" : "zh")
              localStorage.setItem("userLangStorage", i18n.language)
            }}
            style={{
              cursor: "pointer",
              fontSize: "12px"
            }}
          >
            {i18n.language === "zh" ? "EN" : "中文"}
          </Button>
        </Flex>
        <Flex scale={1} gap={[1.5, 2]}>
          {screen !== 'mobile' && (
            <Link to="/" activeStyle={{ color: color.black }}>
              {t("首页")}
            </Link>
          )}
          {/* <Link
            to="/study"
            activeStyle={{ color: color.black }}
            partiallyActive={true}
          >
            {t("学院")}
          </Link> */}
          <Link
            to="/contact"
            activeStyle={{ color: color.black }}
            partiallyActive={true}
          >
            {t("联系")}
          </Link>
        </Flex>
      </Flex>
    </>
  )
}

const logo = () => (
  <svg width="103" height="32" viewBox="0 0 103 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M51.736 4.524V7.5H55.16V10.508H51.096V13.644H69.912V10.508H65.784V7.5H69.368V4.524H65.784V1.452H62.52V4.524H58.392V1.452H55.16V4.524H51.736ZM58.392 10.508V7.5H62.52V10.508H58.392ZM68.056 15.34H53.144V30.604H56.376V29.42H64.824V30.636H68.056V15.34ZM56.376 26.412V23.82H64.824V26.412H56.376ZM56.376 20.94V18.316H64.824V20.94H56.376ZM49.24 1.516C48.408 2.86 47.512 4.108 46.584 5.196C45.752 4.012 44.792 2.86 43.672 1.74L41.048 3.756C42.296 4.876 43.352 6.124 44.248 7.468C42.968 8.556 41.656 9.42 40.248 10.092L42.04 13.004C43.448 12.108 44.664 11.212 45.752 10.38C45.752 10.38 45.752 10.412 45.784 10.476C46.104 11.34 46.36 12.364 46.552 13.548C45.048 16.012 42.936 18.156 40.216 19.98L42.104 22.796C43.928 21.42 45.56 19.788 47 17.868C47.032 18.508 47.064 19.244 47.064 20.012C47.064 23.916 46.744 26.252 46.168 27.02C45.912 27.34 45.4 27.532 44.568 27.532C43.64 27.532 42.648 27.468 41.624 27.34L42.616 30.444C43.288 30.476 44.184 30.508 45.304 30.508C46.552 30.508 47.544 30.22 48.312 29.644C49.592 28.556 50.264 25.42 50.264 20.172C50.168 15.404 49.656 11.724 48.728 9.132C48.6 8.812 48.44 8.46 48.28 8.108C49.496 6.828 50.744 5.196 51.992 3.212L49.24 1.516ZM96.056 26.988C98.136 26.988 99.192 25.836 99.192 23.532V10.476H88.76V6.156C93.24 5.708 97.176 5.068 100.568 4.236L98.872 1.324C91.768 2.86 83.128 3.66 72.92 3.66L73.976 6.796C78.072 6.796 81.848 6.668 85.368 6.444V10.476H74.904V27.052H78.232V13.676H85.368V30.892H88.76V13.676H95.864V22.796C95.864 23.436 95.544 23.788 94.936 23.788L92.152 23.628L93.016 26.988H96.056Z" fill="black" />
      <path d="M51.736 4.524V7.5H55.16V10.508H51.096V13.644H69.912V10.508H65.784V7.5H69.368V4.524H65.784V1.452H62.52V4.524H58.392V1.452H55.16V4.524H51.736ZM58.392 10.508V7.5H62.52V10.508H58.392ZM68.056 15.34H53.144V30.604H56.376V29.42H64.824V30.636H68.056V15.34ZM56.376 26.412V23.82H64.824V26.412H56.376ZM56.376 20.94V18.316H64.824V20.94H56.376ZM49.24 1.516C48.408 2.86 47.512 4.108 46.584 5.196C45.752 4.012 44.792 2.86 43.672 1.74L41.048 3.756C42.296 4.876 43.352 6.124 44.248 7.468C42.968 8.556 41.656 9.42 40.248 10.092L42.04 13.004C43.448 12.108 44.664 11.212 45.752 10.38C45.752 10.38 45.752 10.412 45.784 10.476C46.104 11.34 46.36 12.364 46.552 13.548C45.048 16.012 42.936 18.156 40.216 19.98L42.104 22.796C43.928 21.42 45.56 19.788 47 17.868C47.032 18.508 47.064 19.244 47.064 20.012C47.064 23.916 46.744 26.252 46.168 27.02C45.912 27.34 45.4 27.532 44.568 27.532C43.64 27.532 42.648 27.468 41.624 27.34L42.616 30.444C43.288 30.476 44.184 30.508 45.304 30.508C46.552 30.508 47.544 30.22 48.312 29.644C49.592 28.556 50.264 25.42 50.264 20.172C50.168 15.404 49.656 11.724 48.728 9.132C48.6 8.812 48.44 8.46 48.28 8.108C49.496 6.828 50.744 5.196 51.992 3.212L49.24 1.516ZM96.056 26.988C98.136 26.988 99.192 25.836 99.192 23.532V10.476H88.76V6.156C93.24 5.708 97.176 5.068 100.568 4.236L98.872 1.324C91.768 2.86 83.128 3.66 72.92 3.66L73.976 6.796C78.072 6.796 81.848 6.668 85.368 6.444V10.476H74.904V27.052H78.232V13.676H85.368V30.892H88.76V13.676H95.864V22.796C95.864 23.436 95.544 23.788 94.936 23.788L92.152 23.628L93.016 26.988H96.056Z" fill="black" />
      <g clipPath="url(#clip1)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.0027 32C9.92384 32 4.63547 28.6086 1.92674 23.6154C1.81977 23.4008 1.75961 23.1588 1.75961 22.9027C1.75961 22.4726 1.92926 22.0822 2.20528 21.7947L6.87521 17.1248C7.16422 16.8391 7.56151 16.6627 8 16.6627C8.43849 16.6627 8.83578 16.8391 9.12478 17.1248L13.7373 21.7373C14.9869 22.9869 17.0131 22.9869 18.2627 21.7373L22.8787 17.1213C23.1674 16.8377 23.5632 16.6627 23.9999 16.6627C24.445 16.6627 24.8476 16.8444 25.1377 17.1377L29.827 21.827C30.0852 22.1112 30.2425 22.4885 30.2425 22.9027C30.2425 23.1519 30.1856 23.3878 30.0839 23.598C27.3797 28.6007 22.0886 32 16.0027 32Z" fill="#615AF2" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2425 9.09568C30.2425 9.5208 30.0767 9.90719 29.8063 10.1937L25.1374 14.8626C24.8476 15.1555 24.445 15.3372 23.9999 15.3372C23.5578 15.3372 23.1576 15.1578 22.868 14.868L18.2627 10.2627C17.0131 9.01306 14.9869 9.01306 13.7373 10.2627L9.13753 14.8625C8.84768 15.1555 8.44506 15.3372 8 15.3372C7.55787 15.3372 7.15763 15.1578 6.86804 14.868L2.24577 10.2458C1.94639 9.9545 1.75961 9.54691 1.75961 9.09568C1.75961 8.81844 1.83012 8.55766 1.9542 8.33031C4.67045 3.36631 9.94206 0 16 0C22.086 0 27.3784 3.39757 30.0834 8.39923C30.1854 8.60978 30.2425 8.84605 30.2425 9.09568Z" fill="#615AF2" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="103" height="32" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const logoEn = () => (
  <svg width="108" height="32" viewBox="0 0 108 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip2)">
      <path d="M55.75 23.0938H45.5156V3.95312H41.4844V26.5H55.75V23.0938Z" fill="black" />
      <path d="M60.2812 7.4375C61.4688 7.4375 62.4062 6.48438 62.4062 5.32812C62.4062 4.15625 61.4688 3.21875 60.2812 3.21875C59.0938 3.21875 58.1406 4.15625 58.1406 5.32812C58.1406 6.48438 59.0938 7.4375 60.2812 7.4375ZM58.3438 26.5H62.2344V9.9375H58.3438V26.5Z" fill="black" />
      <path d="M76.4688 21.5469C76.0469 22.9219 74.6719 23.8281 72.8594 23.8281C70.3125 23.8281 68.6875 22.0469 68.6875 19.3906V19.1562H80.2656V17.8906C80.2656 12.8594 77.3281 9.59375 72.625 9.59375C67.8438 9.59375 64.8125 13.0469 64.8125 18.2812C64.8125 23.5625 67.8125 26.8281 72.7812 26.8281C76.7031 26.8281 79.6094 24.6719 80.1094 21.5469H76.4688ZM72.6406 12.6094C74.875 12.6094 76.375 14.25 76.4531 16.5938H68.7188C68.875 14.2812 70.4531 12.6094 72.6406 12.6094Z" fill="black" />
      <path d="M92.0469 26.7656C96.2344 26.7656 98.875 23.5156 98.875 18.2188C98.875 12.9219 96.2188 9.65625 92.0469 9.65625C89.7031 9.65625 87.8125 10.8438 86.875 12.7812H86.7812V3.95312H82.8906V26.5H86.7031V23.6719H86.7812C87.75 25.5938 89.6094 26.7656 92.0469 26.7656ZM90.8438 12.8594C93.3281 12.8594 94.8906 14.9375 94.8906 18.2188C94.8906 21.5156 93.3438 23.5781 90.8438 23.5781C88.4375 23.5781 86.7656 21.4688 86.7656 18.2188C86.7656 15 88.4375 12.8594 90.8438 12.8594Z" fill="black" />
      <path d="M103.438 7.4375C104.625 7.4375 105.562 6.48438 105.562 5.32812C105.562 4.15625 104.625 3.21875 103.438 3.21875C102.25 3.21875 101.297 4.15625 101.297 5.32812C101.297 6.48438 102.25 7.4375 103.438 7.4375ZM101.5 26.5H105.391V9.9375H101.5V26.5Z" fill="black" />
      <g clipPath="url(#clip3)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.5027 32C10.4238 32 5.13548 28.6086 2.42675 23.6154C2.31978 23.4008 2.25961 23.1588 2.25961 22.9027C2.25961 22.4726 2.42926 22.0822 2.70529 21.7947L7.37522 17.1248C7.66422 16.8391 8.06152 16.6627 8.5 16.6627C8.93849 16.6627 9.33578 16.8391 9.62479 17.1248L14.2373 21.7373C15.4869 22.9869 17.5131 22.9869 18.7627 21.7373L23.3787 17.1213C23.6674 16.8377 24.0632 16.6627 24.5 16.6627C24.945 16.6627 25.3476 16.8444 25.6377 17.1377L30.327 21.827C30.5852 22.1112 30.7425 22.4885 30.7425 22.9027C30.7425 23.1519 30.6856 23.3878 30.5839 23.598C27.8797 28.6007 22.5886 32 16.5027 32Z" fill="#615AF2" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.7425 9.09568C30.7425 9.5208 30.5767 9.90719 30.3063 10.1937L25.6374 14.8626C25.3476 15.1555 24.945 15.3372 24.5 15.3372C24.0578 15.3372 23.6576 15.1578 23.368 14.868L18.7627 10.2627C17.5131 9.01306 15.4869 9.01306 14.2373 10.2627L9.63754 14.8625C9.34768 15.1555 8.94506 15.3372 8.5 15.3372C8.05788 15.3372 7.65764 15.1578 7.36805 14.868L2.74578 10.2458C2.4464 9.9545 2.25961 9.54691 2.25961 9.09568C2.25961 8.81844 2.33013 8.55766 2.45421 8.33031C5.17046 3.36631 10.4421 0 16.5 0C22.586 0 27.8784 3.39757 30.5834 8.39923C30.6854 8.60978 30.7425 8.84605 30.7425 9.09568Z" fill="#615AF2" />
      </g>
    </g>
    <defs>
      <clipPath id="clip2">
        <rect width="107" height="32" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip3">
        <rect width="32" height="32" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

const flag = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.36552 39C5.2426 39 5.9361 38.3065 5.9361 37.4294V26.7821C6.44603 26.5577 8.1798 25.905 10.8926 25.905C17.9909 25.905 22.5598 29.4133 29.3317 29.4133C32.3301 29.4133 33.5335 29.0666 35.0225 28.3935C36.3687 27.7815 37.2254 26.7413 37.2254 24.9667V5.50778C37.2254 4.38594 36.3075 3.71283 35.1041 3.71283C34.1046 3.71283 32.3505 4.50832 29.1073 4.50832C22.3559 4.50832 17.7665 1 10.6683 1C7.66987 1 6.48683 1.34675 4.97743 2.01986C3.65162 2.61138 2.77454 3.63124 2.77454 5.3854V37.4294C2.77454 38.2861 3.50884 39 4.36552 39Z" />
  </svg>
)