import React from "react"
import { Flex } from "./Styles"

import { useTranslation } from "react-i18next"


export default () => {
  const { t, i18n } = useTranslation()

  return (
    <Flex childFlex={1} gap={[3, 4]} responsive>
      <a href="https://bifrost.app" target="_blank"><img src="/images/bifrost.png" width="100%" style={{ maxWidth: '576px' }} /></a>
      <a href="https://omni.ls" target="_blank"><img src="/images/omni.png" width="100%" style={{ maxWidth: '576px' }} /></a>
    </Flex>
  )
}

const PoolLogo = () => (
  <svg
    width="158"
    height="32"
    viewBox="0 0 158 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8934 32C10.6894 32 5.29223 28.6086 2.52776 23.6154C2.41858 23.4008 2.35718 23.1588 2.35718 22.9027C2.35718 22.4726 2.53032 22.0822 2.81202 21.7947L7.57807 17.1248C7.87302 16.8391 8.27849 16.6627 8.72601 16.6627C9.17352 16.6627 9.57899 16.8391 9.87394 17.1248L14.5813 21.7373C15.8567 22.9869 17.9246 22.9869 19.2 21.7373L23.9109 17.1213C24.2056 16.8377 24.6096 16.6627 25.0553 16.6627C25.5095 16.6627 25.9204 16.8444 26.2164 17.1377L31.0023 21.827C31.2657 22.1111 31.4263 22.4885 31.4263 22.9027C31.4263 23.1519 31.3682 23.3878 31.2645 23.598C28.5045 28.6007 23.1046 32 16.8934 32Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4263 9.09568C31.4263 9.5208 31.2571 9.90719 30.9811 10.1937L26.2161 14.8626C25.9204 15.1555 25.5095 15.3372 25.0553 15.3372C24.604 15.3372 24.1955 15.1578 23.9 14.868L19.2 10.2627C17.9246 9.01306 15.8567 9.01306 14.5813 10.2627L9.88695 14.8625C9.59113 15.1555 9.18022 15.3372 8.72601 15.3372C8.27478 15.3372 7.8663 15.1578 7.57075 14.868L2.85335 10.2458C2.54781 9.9545 2.35718 9.54691 2.35718 9.09568C2.35718 8.81844 2.42915 8.55766 2.55578 8.33031C5.32794 3.36631 10.708 0 16.8907 0C23.1019 0 28.5032 3.39757 31.2639 8.39923C31.368 8.60978 31.4263 8.84605 31.4263 9.09568Z"
        fill="white"
      />
    </g>
    <path
      d="M52.8945 4.93864V7.66664H56.0331V10.424H52.3078V13.2986H69.5558V10.424H65.7718V7.66664H69.0571V4.93864H65.7718V2.12264H62.7798V4.93864H58.9958V2.12264H56.0331V4.93864H52.8945ZM58.9958 10.424V7.66664H62.7798V10.424H58.9958ZM67.8545 14.8533H54.1851V28.8453H57.1478V27.76H64.8918V28.8746H67.8545V14.8533ZM57.1478 25.0026V22.6266H64.8918V25.0026H57.1478ZM57.1478 19.9866V17.5813H64.8918V19.9866H57.1478ZM50.6065 2.18131C49.8438 3.41331 49.0225 4.55731 48.1718 5.55464C47.4091 4.46931 46.5291 3.41331 45.5025 2.38664L43.0971 4.23464C44.2411 5.26131 45.2091 6.40531 46.0305 7.63731C44.8571 8.63464 43.6545 9.42664 42.3638 10.0426L44.0065 12.712C45.2971 11.8906 46.4118 11.0693 47.4091 10.3066C47.4091 10.3066 47.4091 10.336 47.4385 10.3946C47.7318 11.1866 47.9665 12.1253 48.1425 13.2106C46.7638 15.4693 44.8278 17.4346 42.3345 19.1066L44.0651 21.688C45.7371 20.4266 47.2331 18.9306 48.5531 17.1706C48.5825 17.7573 48.6118 18.432 48.6118 19.136C48.6118 22.7146 48.3185 24.856 47.7905 25.56C47.5558 25.8533 47.0865 26.0293 46.3238 26.0293C45.4731 26.0293 44.5638 25.9706 43.6251 25.8533L44.5345 28.6986C45.1505 28.728 45.9718 28.7573 46.9985 28.7573C48.1425 28.7573 49.0518 28.4933 49.7558 27.9653C50.9291 26.968 51.5451 24.0933 51.5451 19.2826C51.4571 14.912 50.9878 11.5386 50.1371 9.16264C50.0198 8.86931 49.8731 8.54664 49.7265 8.22398C50.8411 7.05064 51.9851 5.55464 53.1291 3.73597L50.6065 2.18131Z"
      fill="white"
    />
    <path
      d="M93.5211 25.5306C95.4278 25.5306 96.3958 24.4746 96.3958 22.3626V10.3946H86.8331V6.43464C90.9398 6.02397 94.5478 5.43731 97.6571 4.67464L96.1025 2.00531C89.5905 3.41331 81.6705 4.14664 72.3131 4.14664L73.2811 7.02131C77.0358 7.02131 80.4971 6.90398 83.7238 6.69864V10.3946H74.1318V25.5893H77.1825V13.328H83.7238V29.1093H86.8331V13.328H93.3451V21.688C93.3451 22.2746 93.0518 22.5973 92.4945 22.5973L89.9425 22.4506L90.7345 25.5306H93.5211Z"
      fill="white"
    />
    <path
      d="M104.55 6.17064C103.905 10.072 102.673 13.592 100.796 16.76L102.438 19.4C102.79 18.872 103.172 18.2853 103.524 17.6986V28.0826H106.398V26.176H110.769C110.593 26.4986 110.388 26.8506 110.153 27.2026L112.5 29.3146C114.905 25.648 116.166 20.7786 116.284 14.736V8.66398H127.782V5.67198H122.297C121.916 4.26398 121.534 2.97331 121.124 1.85864L118.014 2.38664C118.484 3.38398 118.894 4.49864 119.276 5.67198H113.233V14.736C113.145 18.96 112.47 22.48 111.209 25.2666V11.9493H106.105C106.692 10.1306 107.19 8.22398 107.542 6.17064H111.972V3.23731H101.91V6.17064H104.55ZM106.398 23.3893V14.7653H108.452V23.3893H106.398Z"
      fill="white"
    />
    <path
      d="M152.628 28.3466C154.329 28.3466 155.502 28.024 156.148 27.408C156.793 26.7333 157.204 24.856 157.438 21.776L154.564 20.808C154.446 23.1253 154.212 24.4746 153.889 24.8853C153.508 25.2373 152.892 25.4426 152.07 25.4426H144.532C143.593 25.4426 143.124 24.9733 143.124 24.064V13.24L146.35 12.184V22.5973H149.372V11.1866L152.833 10.0426C152.833 13.504 152.774 15.528 152.716 16.0853C152.657 16.7306 152.452 17.0826 152.1 17.0826C151.572 17.0826 150.926 17.0533 150.193 17.0533L151.044 19.8986H151.513C153.742 19.8986 155.033 19.1946 155.356 17.816C155.649 16.408 155.796 12.4186 155.796 5.84798L149.372 7.98931V1.85864H146.35V9.01598L143.124 10.1013V4.46931H140.073V11.128L137.99 11.832V14.912L140.073 14.2373V24.5333C140.073 27.056 141.305 28.3466 143.828 28.3466H152.628ZM133.033 2.29864L130.862 4.41064C133.092 5.99464 134.793 7.49064 135.996 8.83998L138.196 6.66931C136.788 5.20264 135.057 3.76531 133.033 2.29864ZM132.505 9.48531L130.334 11.5973C132.417 13.1226 134.001 14.5306 135.116 15.88L137.286 13.68C135.996 12.272 134.382 10.8933 132.505 9.48531ZM134.881 17.5226C133.678 20.984 132.241 24.3573 130.598 27.672L133.678 29.0213C135.145 25.7946 136.494 22.3333 137.668 18.6373L134.881 17.5226Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0">
        <rect
          width="32.6586"
          height="32"
          fill="white"
          transform="translate(0.561279)"
        />
      </clipPath>
    </defs>
  </svg>
)

const PoolLogoEn = () => (
  <svg width="174" height="32" viewBox="0 0 174 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M55.0319 23.0938H44.7975V3.95312H40.7663V26.5H55.0319V23.0938Z" fill="white" />
    <path d="M59.5632 7.4375C60.7507 7.4375 61.6882 6.48438 61.6882 5.32812C61.6882 4.15625 60.7507 3.21875 59.5632 3.21875C58.3757 3.21875 57.4225 4.15625 57.4225 5.32812C57.4225 6.48438 58.3757 7.4375 59.5632 7.4375ZM57.6257 26.5H61.5163V9.9375H57.6257V26.5Z" fill="white" />
    <path d="M75.7507 21.5469C75.3288 22.9219 73.9538 23.8281 72.1413 23.8281C69.5944 23.8281 67.9694 22.0469 67.9694 19.3906V19.1562H79.5475V17.8906C79.5475 12.8594 76.61 9.59375 71.9069 9.59375C67.1257 9.59375 64.0944 13.0469 64.0944 18.2812C64.0944 23.5625 67.0944 26.8281 72.0632 26.8281C75.985 26.8281 78.8913 24.6719 79.3913 21.5469H75.7507ZM71.9225 12.6094C74.1569 12.6094 75.6569 14.25 75.735 16.5938H68.0007C68.1569 14.2812 69.735 12.6094 71.9225 12.6094Z" fill="white" />
    <path d="M91.3288 26.7656C95.5163 26.7656 98.1569 23.5156 98.1569 18.2188C98.1569 12.9219 95.5007 9.65625 91.3288 9.65625C88.985 9.65625 87.0944 10.8438 86.1569 12.7812H86.0632V3.95312H82.1725V26.5H85.985V23.6719H86.0632C87.0319 25.5938 88.8913 26.7656 91.3288 26.7656ZM90.1257 12.8594C92.61 12.8594 94.1725 14.9375 94.1725 18.2188C94.1725 21.5156 92.6257 23.5781 90.1257 23.5781C87.7194 23.5781 86.0475 21.4688 86.0475 18.2188C86.0475 15 87.7194 12.8594 90.1257 12.8594Z" fill="white" />
    <path d="M102.719 7.4375C103.907 7.4375 104.844 6.48438 104.844 5.32812C104.844 4.15625 103.907 3.21875 102.719 3.21875C101.532 3.21875 100.579 4.15625 100.579 5.32812C100.579 6.48438 101.532 7.4375 102.719 7.4375ZM100.782 26.5H104.673V9.9375H100.782V26.5Z" fill="white" />
    <path d="M113.129 3.95312V26.5H117.16V19.0469H121.723C126.285 19.0469 129.488 16 129.488 11.5C129.488 7 126.379 3.95312 121.894 3.95312H113.129ZM117.16 7.25H120.832C123.723 7.25 125.394 8.79688 125.394 11.5C125.394 14.2344 123.707 15.7969 120.816 15.7969H117.16V7.25Z" fill="white" />
    <path d="M138.754 26.8281C143.613 26.8281 146.769 23.5938 146.769 18.2188C146.769 12.8594 143.582 9.59375 138.754 9.59375C133.926 9.59375 130.738 12.875 130.738 18.2188C130.738 23.5938 133.894 26.8281 138.754 26.8281ZM138.754 23.75C136.285 23.75 134.691 21.75 134.691 18.2188C134.691 14.7031 136.301 12.6875 138.754 12.6875C141.223 12.6875 142.816 14.7031 142.816 18.2188C142.816 21.75 141.223 23.75 138.754 23.75Z" fill="white" />
    <path d="M156.598 26.8281C161.457 26.8281 164.613 23.5938 164.613 18.2188C164.613 12.8594 161.426 9.59375 156.598 9.59375C151.769 9.59375 148.582 12.875 148.582 18.2188C148.582 23.5938 151.738 26.8281 156.598 26.8281ZM156.598 23.75C154.129 23.75 152.535 21.75 152.535 18.2188C152.535 14.7031 154.144 12.6875 156.598 12.6875C159.066 12.6875 160.66 14.7031 160.66 18.2188C160.66 21.75 159.066 23.75 156.598 23.75Z" fill="white" />
    <path d="M167.223 26.5H171.113V3.95312H167.223V26.5Z" fill="white" />
    <g clipPath="url(#clip0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0027 32C9.92381 32 4.63545 28.6086 1.92672 23.6154C1.81975 23.4008 1.75958 23.1588 1.75958 22.9027C1.75958 22.4726 1.92923 22.0822 2.20526 21.7947L6.87519 17.1248C7.16419 16.8391 7.56149 16.6627 7.99997 16.6627C8.43846 16.6627 8.83575 16.8391 9.12476 17.1248L13.7372 21.7373C14.9869 22.9869 17.013 22.9869 18.2627 21.7373L22.8787 17.1213C23.1674 16.8377 23.5632 16.6627 23.9999 16.6627C24.445 16.6627 24.8476 16.8444 25.1376 17.1377L29.827 21.827C30.0852 22.1112 30.2425 22.4885 30.2425 22.9027C30.2425 23.1519 30.1855 23.3878 30.0839 23.598C27.3796 28.6007 22.0886 32 16.0027 32Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.2425 9.09568C30.2425 9.5208 30.0767 9.90719 29.8063 10.1937L25.1374 14.8626C24.8476 15.1555 24.445 15.3372 23.9999 15.3372C23.5578 15.3372 23.1576 15.1578 22.868 14.868L18.2627 10.2627C17.013 9.01306 14.9869 9.01306 13.7372 10.2627L9.13751 14.8625C8.84765 15.1555 8.44503 15.3372 7.99997 15.3372C7.55785 15.3372 7.15761 15.1578 6.86802 14.868L2.24575 10.2458C1.94637 9.9545 1.75958 9.54691 1.75958 9.09568C1.75958 8.81844 1.8301 8.55766 1.95417 8.33031C4.67043 3.36631 9.94203 0 16 0C22.086 0 27.3783 3.39757 30.0834 8.39923C30.1853 8.60978 30.2425 8.84605 30.2425 9.09568Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
