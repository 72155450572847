import React, { useState, useEffect } from "react"

import "./normalize.css"
import "../i18n/i18n"
import Header from "./Header"
import Footer from "./Footer"
import { GlobalStyle, SVG, color } from "./Styles"

export default ({ children }) => {
  const [screen, setScreen] = useState("mobile");

  useEffect(() => {
    if (document.documentElement.clientWidth < 720) {
      setScreen("mobile");
    } else {
      setScreen("desktop");
    }
  });

  return (
    <GlobalStyle>
      <Header screen={screen} />
      <main>{children}</main>
      <Footer />

      <SVG
        svg={up}
        pointer
        scale={1}
        fill={color.gray4}
        position="fixed"
        bottom={0}
        right={0}
        p={0.75}
        onClick={() => window.scrollTo(0, 0)}
      />
    </GlobalStyle>
  )
}

const up = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 16.0077C12.3866 16.0077 16 12.3943 16 8.00766C16 3.62105 12.3789 0 7.99234 0C3.60574 0 0 3.62105 0 8.00766C0 12.3943 3.6134 16.0077 8 16.0077ZM8 3.72057C8.26794 3.72057 8.4823 3.82775 8.67368 4.02679L11.399 6.83636C11.5445 6.99713 11.6211 7.16555 11.6211 7.38756C11.6211 7.80096 11.3148 8.11483 10.8785 8.11483C10.6641 8.11483 10.4651 8.02297 10.3349 7.87751L9.43158 6.91292L8.75024 6.02488L8.82679 7.67847V11.4986C8.82679 11.9579 8.47464 12.2871 8 12.2871C7.52536 12.2871 7.16555 11.9579 7.16555 11.4986V7.67847L7.25742 6.03254L6.57608 6.89761L5.66507 7.87751C5.54258 8.01531 5.33589 8.11483 5.12153 8.11483C4.68517 8.11483 4.3866 7.80096 4.3866 7.38756C4.3866 7.16555 4.44785 6.99713 4.60096 6.83636L7.32632 4.02679C7.52536 3.8201 7.73971 3.72057 8 3.72057Z" />
  </svg>
)
